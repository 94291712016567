@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.collapsible .content {
  padding: 6px;
  background-color: rgb(240, 240, 240);
  font-size: 14px;
}

.collapsible .header {
  background-color: rgb(200, 200, 200);
  padding: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgb(220, 220, 220);
}

.collapsible .header .title {
  font-weight: 400;
}

.preferences label {
  display: block;
  padding-bottom: 4px;
}

.ant-picker-calendar-full {
  min-width: 1000px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 120px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}

/* .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view .ant-picker-cell-inner.ant-picker-calendar-date .ant-picker-calendar-date-content:after { */
.red-cross {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* content: "\274C"; */
  font-size: 80px;
  color: rgba(255, 55, 55, 0.66);
  text-align: center;
}

/* .deadpool-content::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: -1;
} */

.deadpool-wrap {
  overflow: hidden;
  position: relative;
}

.deadpool-bg {
  opacity: 0.6;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.deadpool-content {
  position: relative;
}

.arrow {
  color: red;
  text-align: center;
  margin: 8% 0;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.arrow-down-container {
  width: 25px;
  /* cubic-bezier-easing = sine / mehr Beispiele: https://easings.net/ */
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  cursor: pointer;
  height: 20px;
}

.arrow-down {
  height: 6px;
  background: black;
  transform: rotate(45deg);
  transform-origin: 0% 0%;
  border-radius: 5px;
}

.arrow-down:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 100%;
  border-radius: 5px;
  background: black;
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
}

@keyframes bounce {
  50% {
    transform: translateY(-15px);
  }
}

/* @keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
} */

.shift-container {
  transform: translateX(10px);
  animation: shift 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes shift {
  50% {
    transform: translateX(-10px);
  }
}

.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}